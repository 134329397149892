import { useMsal } from "@azure/msal-react";
import { CircularProgress, List, ListItem, Paper, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ResultResponse } from "../../types";
import Title from "../common/Title";
import DefaultHeaders from "../helpers/DefaultHeaders";
import css from "./Questions.module.css";

const Results = () => {
  const [fetching, setFetching] = useState(true);
  const [fetchingIndicator, setFetchingIndicator] = useState(true);
  const [results, setResults] = useState<ResultResponse[]>([]);
  const userId = parseInt(useParams().userId || "", 10);
  const msal = useMsal();

  const fetchData = async() => {
    try {
      const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/admin/result/${userId}`, {
        headers: await DefaultHeaders(msal),
      });
      setResults(data);
    } catch(e) {
      console.error(e);
    }
    setFetching(false);
    setFetchingIndicator(false);
  };

  useEffect(() => {
    fetchData();
  },[fetching]);

  return <Paper className={css.displayFlexColumn}>
    <Title>Results</Title>
    <Link to="/admin/users"><Typography>Back</Typography></Link>
    {fetchingIndicator ? <CircularProgress key="loader" className={css.loader} /> :
    <List key="list">
      {results.map((result) => <ListItem key={result.result.id}><Typography>{JSON.stringify(result)}</Typography></ListItem>)}
    </List>}
  </Paper>;
};

export default Results;