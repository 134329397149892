import { QuestionProps } from "../../types";
import FillTheGaps from "./FillTheGaps";
import FreeText from "./FreeText";
import MultipleChoice from "./MultipleChoice";
import SingleChoice from "./SingleChoice";

const Question = (props: QuestionProps) => {
  switch(props.question.type) {
    case "fillTheGaps":
      return <FillTheGaps {...props} />;
    case "multipleChoice":
      return <MultipleChoice {...props} />;
    case "singleChoice":
      return <SingleChoice {...props} />;
    case "freeText":
      return <FreeText {...props} />;
    default:
      return <FillTheGaps {...props} />;
  }
};

export default Question;