
import { List, ListItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Block } from "../../types";
import css from "./Questions.module.css";

interface BlocksProps {
  blocks: Block[];
}

const Blocks = (props: BlocksProps) => {
  const [blocks, setBlocks] = useState<Block[]>([]);
  useEffect(() => {
    setBlocks(props.blocks);
  },[props.blocks]);

  return <List className={css.displayFlexColumn}>
    {blocks.map((block, i) => <Link to={`/admin/block/${block.id}`} key={`blocklist${i}`}><ListItem>
      <Typography className={css.displayBlock}>Block: {block.name} id:{block.id}</Typography>
      <Typography className={css.displayBlock}>{`${JSON.stringify(block.rule)}`}</Typography>
      <Typography className={css.displayBlock}>{`${block.first ? "This is the first block" : ""}`}</Typography>
    </ListItem>
    </Link>)}
  </List>;
};

export default Blocks;