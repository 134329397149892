import { Grid, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Answer, QuestionProps } from "../../types";
import css from "./Question.module.css";

const FillTheGaps = (props: QuestionProps) => {
  const {updateAnswer, index} = props;
  const questionData = props.question.data;
  const [answer, setAnswer] = useState<Answer>(props.answers[props.index]);

  useEffect(() => {
    const trimmedAnswers = answer.answers.map((answer) => answer.trim());
    const newAnswer = {...answer};
    newAnswer.answers = trimmedAnswers;
    updateAnswer(newAnswer, index);
  },[answer]);

  const updateLocalAnswer = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, i: number) => {
    const {value} = e.target;
    const newAnswer = {...answer};
    newAnswer.answers[i] = value;
    setAnswer(newAnswer);
    props.updateAnswered(props.index, newAnswer.answers.reduce((acc, cur) => {
      return acc || cur.lenght !== 0;
    }));
  };

  const insertGaps = (arr: any[]) => {
    const newArr = [...arr];
    for (let i = 1, j = 0; j < arr.length - 1; i+=2, j++) {
      newArr.splice(i, 0, <TextField
        value={answer.answers[j] || ""}
        key={`question${props.index}gap${i}`}
        onChange={(e) => updateLocalAnswer(e, j)}
      />);
    }
    return newArr;
  };

  return <React.Fragment>
    <Grid className={css.displayFlex}>
      <Typography className={css.questionNumber}>{props.index + 1}</Typography>
      <Typography className={css.questionTitle}>Fill in the gaps using ONE WORD</Typography>
    </Grid>
    <Grid className={css.fillTheGapsBody}>
      {insertGaps(questionData.text.map((part, i) =>
        <Typography className={css.fillTheGapsText} key={`question${props.index}part${i}`}>
          &nbsp;{part}&nbsp;
        </Typography>))}
    </Grid>
  </React.Fragment>;
};

export default FillTheGaps;