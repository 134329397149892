import { useEffect, useState } from "react";
import axios from "axios";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import DefaultHeaders from "../helpers/DefaultHeaders";
import { Block, Question } from "../../types";
import { getQuestionDescription } from "../helpers/GetQuestionType";
import css from "./Questions.module.css";
import { Link } from "react-router-dom";

interface QuestionsProps {
  blocks: Block[];
}

const Questions = (props: QuestionsProps) => {
  const [fetching, setFetching] = useState(true);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [blocks, setBlocks] = useState<Block[]>([]);
  const msal = useMsal();

  useEffect(() => {
    setBlocks(props.blocks);
  },[props.blocks]);

  useEffect(() => {
    const fetchData = async() => {
      try {
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL || ""}/admin/questions`, {
          headers: await DefaultHeaders(msal)
        });
        setQuestions(data);
      } catch(e) {
        console.error(e);
      }
      setFetching(false);
    };
    fetchData();
  },[fetching]);
  return fetching ? <CircularProgress className={css.loader} /> : <Grid className={css.displayFlexWrap}>
    {questions && questions.map((question) => <Grid key={`question${question.id}`}>
      <Typography display="block">Question {question.id}</Typography>
      <Typography display="block">{getQuestionDescription(question.type)}</Typography>
      <Typography display="block">Block: {blocks.find((block) => block.id === question.block)?.name || ""}</Typography>
      <Typography display="block">Text: {question.data.text.join(",")}</Typography>
      {(question.type === "multipleChoice" || question.type === "singleChoice") &&
      <Typography display="block">Choices: {question.data.choices.join(",")}</Typography>}
      {question.type !== "freeText" &&
      <Typography display="block">Checks: {question.data.check.map((check) => typeof check === "number" ? question.data.choices[check] : check).join(",")}</Typography>}
      <Link to={`/admin/question/${question.id}`}>
        <Button
        >
          Edit
        </Button>
      </Link>
    </Grid>)}
  </Grid>;
};

export default Questions;