import { Button, Grid, TextareaAutosize } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { QuestionData } from "../../types";
import Title from "../common/Title";

interface FreeTextFormProps {
  question?: QuestionData;
  setQuestion: Function;
}

const FreeTextForm = (props: FreeTextFormProps) => {
  const [data, setData] = useState<QuestionData>({text: [""], choices: [], check: []});
  const [text, setText] = useState("");

  useEffect(() => {
    if (props.question !== undefined) {
      setData(props.question);
      setText(props.question.text[0]);
    }
  },[props.question]);

  const updateQuestion = () => {
    props.setQuestion(data);
  };

  const updateText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const {value} = e.target;
    setText(value);
    const newData = {...data};
    newData.text = [value];
    setData(newData);
  };

  return <Grid>
    <Title>Create/edit free text question</Title>
    <TextareaAutosize
      minRows={3}
      onChange={(e) => updateText(e)}
      value={text}
    />
    <Button
      variant="contained"
      size="small"
      onClick={() => {
        updateQuestion();
      }}
    >
      Submit
    </Button>
  </Grid>;
};

export default FreeTextForm;