import {
  FormControlLabel,
  FormControlLabelProps,
  Grid,
  Radio,
  RadioGroup,
  styled,
  Typography,
  useRadioGroup
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Answer, QuestionProps } from "../../types";
import css from "./Question.module.css";

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} />
))(({ checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: "#66C8FF",
  },
}));

const MyFormControlLabel = (props: FormControlLabelProps) => {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
};

const SingleChoice = (props: QuestionProps) => {
  const questionData = props.question.data;
  const [answer, setAnswer] = useState<Answer>(props.answers[props.index]);

  useEffect(() => {
    props.updateAnswer(answer, props.index);
  },[answer]);

  const updateChoice = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    const newAnswer = {...answer};
    const choiceIndex = parseInt(value, 10);
    newAnswer.answers[0] = choiceIndex;
    setAnswer(newAnswer);
    props.updateAnswered(props.index);
  };

  const updateAnswered = () => {
    props.updateAnswered(props.index);
  };

  return <React.Fragment>
    <Grid className={css.displayFlex}>
      <Typography className={css.questionNumber}>{props.index + 1}</Typography>
      <Grid className={css.questionTitle}>
        <Typography className={css.questionTitleText}>Select&nbsp;</Typography>
        <Typography className={css.questionTitleBoldText} sx={{ fontWeight: "bold" }}>one</Typography>
        <Typography className={css.questionTitleText}>&nbsp;correct answer</Typography>
      </Grid>
    </Grid>
    <Typography className={css.questionBodyHeader}>{questionData.text}</Typography>
    <Grid className={css.singleChoiceQuestionBody}>
      <RadioGroup value={answer.answers[0]} onClick={updateAnswered} onChange={(e) => updateChoice(e)}>
        {questionData.choices.map((choice, i) =>
          <MyFormControlLabel
            key={`formControlLabel${i}`}
            value={i}
            control={<Radio />}
            label={choice}
          />
        )}
      </RadioGroup>
    </Grid>
  </React.Fragment>;
};

export default SingleChoice;