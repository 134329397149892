import { useMsal } from "@azure/msal-react";
import { CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { Container } from "@mui/system";
// eslint-disable-next-line no-unused-vars, unused-imports/no-unused-imports, @typescript-eslint/no-unused-vars
import axios, { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Block, QuestionData, QuestionType, questionTypes } from "../../types";
import Pulldown from "../common/Pulldown";
import Title from "../common/Title";
import DefaultHeaders from "../helpers/DefaultHeaders";
import { getQuestionTypeIdFromDescription } from "../helpers/GetQuestionType";
import QuestionForm from "./QuestionForm";
import css from "./Questions.module.css";

const CreateQuestion = () => {
  const [fetching, setFetching] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [type, setType] = useState<QuestionType>("fillTheGaps");
  const [value, setValue] = useState<string>("Fill the gaps");
  const [block, setBlock] = useState<Block>({name:""});
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [question, setQuestion] = useState<QuestionData>();
  const msal = useMsal();

  useEffect(() => {
    if (question !== undefined) {
      setError("");
      setSuccess("");
      setSubmitting(true);
      const fetchData = async() => {
        try {
          const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/admin/question`, {
            type,
            block: block.id,
            data: question,
          }, {
            headers: await DefaultHeaders(msal),
          });
          setSuccess(data);
        } catch(e: AxiosError | any) {
          setError(e.message);
          console.error(e);
        }
        setSubmitting(false);
      };
      fetchData();
    }
  },[question]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      const fetchData = async() => {
        try {
          const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/admin/blocks`, {
            headers: await DefaultHeaders(msal),
          });
          setBlocks(data);
        } catch(e) {
          console.error(e);
        }
        setFetching(false);
      };
      fetchData();
    }
    return () => {mounted = false;};
  },[fetching]);

  useEffect(() => {
    setBlock(blocks.find((block) => block.name) || {name:""});
  },[blocks]);

  return <Container>
    <Paper className={css.displayFlexColumn}>
      <Link to="/admin"><Typography>Back</Typography></Link>
      <Title>Create Question</Title>
      {fetching || submitting ? <CircularProgress className={css.loader}/> :
      <React.Fragment><Grid>
        <Pulldown
          arr={questionTypes.map((question) => question.description)}
          name="questionType"
          value={value}
          label="Question type"
          handleStateInputChange={(e) => {
            const { value } = e.target;
            setValue(value);
            setType(getQuestionTypeIdFromDescription(value));
            setSuccess("");
          }}
        />
        <Pulldown
          arr={blocks.map((block) => block.name)}
          name="blocks"
          value={block.name}
          label="Block"
          handleStateInputChange={(e) => {
            const { value } = e.target;
            setBlock(blocks.find((block) => block.name === value) || {name: ""});
          }}
        />
      </Grid>
    <QuestionForm key={type} type={type} setQuestion={setQuestion} />
    <Typography>{success}</Typography>
    <Typography className={css.error}>{error}</Typography>
    </React.Fragment>}
    </Paper>
  </Container>;
};

export default CreateQuestion;