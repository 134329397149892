import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import {
  Route, Routes, useLocation, useNavigate
} from "react-router-dom";
import Admin from "./components/admin/Admin";
import CreateBlock from "./components/admin/CreateBlock";
import CreateQuestion from "./components/admin/CreateQuestion";
import EditBlock from "./components/admin/EditBlock";
import EditQuestion from "./components/admin/EditQuestion";
import Users from "./components/admin/Users";
import AssesmentTest from "./components/AssesmentTest";
import Completed from "./components/Completed";
import Hello from "./components/Hello";
import Terms from "./components/Terms";
import Results from "./components/admin/Results";

const AuthenticatedApp = () => {
  const msal = useMsal();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.match("^/admin")) {
      const groups = msal.instance.getActiveAccount()?.idTokenClaims?.groups as string[] | undefined;
      if (groups === undefined || !groups.includes(process.env.REACT_APP_ADMIN_GROUP_ID || "")) {
        navigate("/");
      }
    }
  });

  return <Routes>
    <Route path="/" element={<Terms />} />
    <Route path="hello" element={<Hello />} />
    <Route path="test" element={<AssesmentTest />} />
    <Route path="completed" element={<Completed />} />
    <Route path="admin" element={<Admin />} />
    <Route path="admin/question" element={<CreateQuestion /> } />
    <Route path="admin/question/:questionId" element={<EditQuestion />} />
    <Route path="admin/block" element={<CreateBlock /> } />
    <Route path="admin/block/:blockId" element={<EditBlock /> } />
    <Route path="admin/users" element={<Users /> } />
    <Route path="admin/result/:userId" element={<Results />} />
  </Routes>;
};

export default AuthenticatedApp;