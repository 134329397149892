export interface Claims {
  aud: string;
  tid: string;
  exp: number;
  groups: string[];
}

export interface Token {
  secret: string;
}

export interface Question {
  id: string;
  type: QuestionType;
  block: number;
  data: QuestionData;
}

export type QuestionData = {
  text: string[],
  choices: string[],
  check: any[]
}

export const questionTypes: {
  id: QuestionType;
  description: string;
}[] = [
  {id: "fillTheGaps", description: "Fill the gaps"},
  {id: "multipleChoice", description: "Multiple choice"},
  {id: "singleChoice", description: "Single choice"},
  {id: "freeText", description: "Free text input"},
];

export type QuestionType = "fillTheGaps" | "multipleChoice" | "singleChoice" | "freeText";

export interface Block {
  id?: number;
  name: string;
  rule?: BlockRule;
  first?: boolean;
}

export type QuestionResponse = {
  id: number;
  type: QuestionType;
  block: number;
  data: QuestionResponseData;
  answers: Answer[];
}

export type QuestionResponseData = {
  text: string[],
  choices: string[],
}

export type Answer = {
  questionId: number,
  answers: any[],
}

export interface QuestionProps {
  question: QuestionResponse;
  answers: Answer[];
  updateAnswer: Function;
  index: number;
  updateAnswered: Function;
}

export type BlockRule = {
  percentageCorrect: number,
  passBlock: number,
  failBlock: number,
  passGrade: string,
  failGrade: string,
}

export interface User {
  id: number;
  name: string;
  email: string;
  block: number;
  result: string;
}

export interface Result {
  id: number;
  user: number;
  block: number;
  answers: Answer[];
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface ResultResponse {
  result: Result;
  score?: number;
}