import { useMsal } from "@azure/msal-react";
import { Button, CircularProgress, List, ListItem, Paper, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Block, User } from "../../types";
import Title from "../common/Title";
import DefaultHeaders from "../helpers/DefaultHeaders";
import css from "./Questions.module.css";

const Users = () => {
  const [fetching, setFetching] = useState(true);
  const [fetchingIndicator, setFetchingIndicator] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [blocks, setBlocks] = useState<Block[]>([]);
  const msal = useMsal();

  const fetchData = async() => {
    try {
      const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/admin/blocks`, {
        headers: await DefaultHeaders(msal),
      });
      setBlocks(data);
    } catch(e) {
      console.error(e);
    }
    try {
      const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/admin/users`, {
        headers: await DefaultHeaders(msal),
      });
      setUsers(data);
    } catch(e) {
      console.error(e);
    }
    setFetching(false);
    setFetchingIndicator(false);
  };

  useEffect(() => {
    fetchData();
  },[fetching]);

  const resetTestResult = async(userId: number) => {
    setFetchingIndicator(true);
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/admin/user/reset`, {
        userId,
      }, {
        headers: await DefaultHeaders(msal),
      });
      fetchData();
    } catch(e) {
      console.error(e);
    }
  };

  return <Paper className={css.displayFlexColumn}>
    <Title>Users</Title>
    <Link to="/admin"><Typography>Back</Typography></Link>
    {fetchingIndicator ? <CircularProgress className={css.loader} /> :
    <List>
      {users.map((user, i) => <ListItem className={css.displayFlexColumn} key={`userlist${i}`}>
        <Typography className={css.displayBlock}>Name: {user.name}</Typography>
        <Typography className={css.displayBlock}>Email: {user.email}</Typography>
        <Typography className={css.displayBlock}>Current block: {user.block === -1 ? "Completed test" : blocks.find((block) => block.id === user.block)?.name || ""}</Typography>
        <Button
          onClick={() => resetTestResult(user.id)}
        >
          Reset
        </Button>
        <Link to={`/admin/result/${user.id}`}>
        <Button>
          See results
        </Button>
        </Link>
        <Typography className={css.displayBlock}>Result: {user.result}</Typography>
      </ListItem>)}
    </List>}
  </Paper>;
};

export default Users;