import { useMsal } from "@azure/msal-react";
import { AppBar, Button, CircularProgress, Divider, Grid, Paper, Toolbar, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useConfirm } from "material-ui-confirm";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Answer, QuestionResponse } from "../types";
import DefaultHeaders from "./helpers/DefaultHeaders";
import Question from "./questions/Question";
import css from "./AssesmentTest.module.css";
import confirmCss from "./Confirm.module.css";

const AssesmentTest = () => {
  const [fetching, setFetching] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [timeLeft, setTimeLeft] = useState<string>();
  const [time, setTime] = useState<Date>();
  const [questions, setQuestions] = useState<QuestionResponse[]>();
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [answered, setAnswered] = useState<boolean[]>([]);
  const msal = useMsal();
  const navigate = useNavigate();
  const confirm = useConfirm();

  const addMinutes = (date: Date, minutes: number) => {
    return new Date(date.getTime() + minutes * 60000);
  };

  const formatTime = (timeDiff: number): string => {
    const minutes = Math.floor(timeDiff / 60);
    const seconds = timeDiff - minutes * 60;

    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const isAlertTime = (): boolean => {
    if (time === undefined) {
      return false;
    }
    const currentTime = new Date();
    currentTime.setMilliseconds(0);
    const timeDiff = (time.getTime() - currentTime.getTime())/1000;
    return timeDiff <= 30;
  };

  useEffect(() => {
    if (timeLeft === "00:00") {
      confirm({
        title: "Oops, time is up!",
        description: "Looks like you have run out of time. To move to the new block click the button.",
        allowClose: false,
        titleProps: {className: confirmCss.confirmTitle},
        contentProps: {className: confirmCss.confirmBody},
        cancellationButtonProps: {
          disabled: true,
          className: confirmCss.hideCancelButton
        },
        confirmationButtonProps: {style: {backgroundColor: "#FFCD1D", margin: "70px 70px 70px 70px"}},
        confirmationText: "NEXT BLOCK"
      }).then(() => {
        submitAnswers();
      }).catch(() => {});
    }
  },[timeLeft]);

  const updateTime = () => {
    if (time === undefined) {
      return;
    }
    const currentTime = new Date();
    currentTime.setMilliseconds(0);
    const timeDiff = (time.getTime() - currentTime.getTime())/1000;
    if (timeDiff < 0) {
      setTimeLeft("00:00");
    } else {
      setTimeLeft(formatTime(timeDiff));
    }
  };

  useEffect(() => {
    if (time !== undefined) {
      const interval = setInterval(() => {
        updateTime();
      }, 1000);
      return () => clearInterval(interval);
    }
  },[time]);

  useEffect(() => {
    const storedValue = localStorage.getItem("accepted");
    if (storedValue !== "true") {
      navigate("/");
    }

    const fetchData = async() => {
      try {
        const {data, status} = await axios.get(`${process.env.REACT_APP_API_URL}/test`, {
          headers: await DefaultHeaders(msal),
        });
        if (status === 204) {
          navigate("/completed");
          return;
        }
        setQuestions(data.questions);
        const answers = data.questions.map((question: QuestionResponse) => {
          return {
            questionId: question.id,
            answers: question.answers || (question.type === "singleChoice" ? [0] : [])
          };
        });
        setAnswers(answers);
        setAnswered(data.questions.map((question: QuestionResponse) => question.type === "singleChoice"));
        const startedTime = addMinutes(new Date(data.startTime), 10);
        startedTime.setMilliseconds(0);
        setTime(startedTime);
      } catch(e) {
        console.error(e);
      }
      setFetching(false);
    };
    if (fetching) {
      fetchData();
    }
  },[fetching]);

  const submitAnswers = () => {
    const submit = async() => {
      try {
        const {data, status} = await axios.post(`${process.env.REACT_APP_API_URL}/test`, {
          answers
        }, {
          headers: await DefaultHeaders(msal),
        });
        if (status === 204) {
          navigate("/completed");
          return;
        }
        setQuestions(data.questions);
        const oldAnswers = data.questions.map((question: QuestionResponse) => {
          return {
            questionId: question.id,
            answers: question.answers || (question.type === "singleChoice" ? [0] : [])
          };
        });
        setAnswers(oldAnswers);
        setAnswered(data.questions.map((question: QuestionResponse) => question.type === "singleChoice"));
        const startedTime = addMinutes(new Date(data.startTime), 10);
        startedTime.setMilliseconds(0);
        setTime(startedTime);
      } catch(e) {
        console.error(e);
      }
      setSubmitting(false);
    };
    setSubmitting(true);
    submit();
  };

  const confirmSubmit = async() => {
    if (questions === undefined) {
      return;
    }
    const numberAnswered = answered.filter((answered) => answered).length;
    confirm({
      title: "Confirmation",
      description: `You've answered ${numberAnswered}/${questions?.length} questions in this block.
                    Note, that after moving to the next block you won’t be able to go back and edit answers.`,
      titleProps: {className: confirmCss.confirmTitle},
      contentProps: {className: confirmCss.confirmBody},
      confirmationButtonProps: {style: {backgroundColor: "#FFCD1D", margin: "70px 70px 70px 5px"}},
      cancellationButtonProps: {style: {margin: "70px 5px 70px 70px"}},
      cancellationText: "BACK",
      confirmationText: "NEXT BLOCK"
    }).then(() => {
      submitAnswers();
    }).catch(() => {

    });
  };

  const updateAnswer = (answer: Answer, index: number) => {
    if (timeLeft !== "00:00") {
      const newAnswers = [...answers];
      newAnswers[index] = answer;
      setAnswers(newAnswers);
    }
  };

  const updateAnswered = (index: number, questionAnswered: boolean = true) => {
    const newAnswered = [...answered];
    newAnswered[index] = questionAnswered;
    setAnswered(newAnswered);
  };

  return <Grid className={css.lightBackground}>
    <AppBar position="fixed" className={`${css.appBar} ${isAlertTime() && css.appBarAlert}`}>
      <Toolbar className={css.toolbar}>
        <img
          src="RHG_vertical_white_symbol_RGB.png"
          alt="Redhill Games"
        />
        <Divider orientation="vertical" variant="middle" flexItem className={css.appBarDivider}/>
        <Grid className={css.displayFlexColumn}>
          <Typography className={css.appBarText}>
            English Level Assesment Test
          </Typography>
          <Typography className={css.appBarName}>
            {msal.instance.getActiveAccount()?.name}
          </Typography>
        </Grid>
        <Grid className={css.appBarClockContainer}>
          <Typography className={css.appBarClock}>{timeLeft}</Typography>
        </Grid>
      </Toolbar>
    </AppBar>
    <Container className={css.staticBackground} style={{backgroundImage: `url(${process.env.PUBLIC_URL + "/background.png"})`}}></Container>
    {fetching || submitting ? <Container className={css.loaderContainer}><CircularProgress className={css.loader}/></Container> :
    <Container className={css.testContainer}>
      <Typography className={css.testInfoText}>Complete {(questions && questions.length) || "10"} questions and press button "Next" to move to the next block of questions.</Typography>
      {questions && questions.map((question: QuestionResponse, i: number) =>
        <Paper className={css.questionContainer} key={`question${i}`}>
          <Question
            question={question}
            answers={answers}
            updateAnswer={updateAnswer}
            index={i}
            updateAnswered={updateAnswered}
          />
        </Paper>
      )}
    </Container>}
    <Grid className={css.footer}>
      <Typography className={css.copyText}>Copyright Redhill Games Ltd 2022</Typography>
      <Button
        variant="contained"
        size="small"
        onClick={() => confirmSubmit()}
        className={css.nextButton}  
      >
        Next Block
      </Button>
    </Grid>
  </Grid>;
};

export default AssesmentTest;