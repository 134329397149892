import { IMsalContext } from "@azure/msal-react";
import GetIdToken from "./GetIdToken";

const DefaultHeaders = async(msal: IMsalContext) => {
  return {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${await GetIdToken(msal)}`
  };
};

export default DefaultHeaders;