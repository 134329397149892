import { Button, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import InfoBlock from "./common/InfoBlock";
import Logo from "./common/Logo";
import css from "./Hello.module.css";
import { GradingOutlined, HelpOutline, LightbulbOutlined, TimerOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

const Hello = () => {
  const [name, setName] = useState<string|undefined>("");
  const msal = useMsal();

  useEffect(() => {
    const accountName = msal.accounts.find((account) => account.name)?.name;
    if (accountName) {
      const firstNameUpperCase = accountName.split(" ").find((str) => str)?.toLocaleUpperCase();
      setName(firstNameUpperCase);
    }
  },[msal.accounts]);

  return <Container className={css.container}>
    <Logo />
    <Typography className={css.helloHeader}>
      {`HI ${name} :)`}
    </Typography>
    <Typography className={css.helloText}>
      Welcome to Redhill’s Testing Portal. You are about to take our online English level test. Read the information below carefully. Then click Let’s Start. Good luck!
    </Typography>
    <Grid className={css.infoContainer}>
      <InfoBlock
        icon={<GradingOutlined className={css.icon}/>}
        color="white"
      >
        <Typography component="h2" variant="h6" display="block" gutterBottom className={css.title}>
        How to complete?
        </Typography>
        <Typography className={css.helloSmallerText}>The test consists of separate blocks of 10 questions each. The number of blocks you need to complete will depend on your answers. The maximum is 5.</Typography>
      </InfoBlock>
      <InfoBlock
        icon={<TimerOutlined className={css.icon}/>}
        color="white"
      >
        <Typography component="h2" variant="h6" display="block" gutterBottom className={css.title}>
        10 min per block
        </Typography>
        <Typography className={css.helloSmallerText}>You have 10 minutes for each block. If you complete early, click Next Block to continue. If you need a break, this would be the right time to have it. The timer will start again after you click Next Block.</Typography>
      </InfoBlock>
    </Grid>
    <Grid className={css.infoContainer}>

      <InfoBlock
        icon={<HelpOutline className={css.icon} />}
        color="white"
      >
        <Typography component="h2" variant="h6" display="block" gutterBottom className={css.title}>
        Three types of questions
        </Typography>
        <ul className={css.list}>
        <Typography className={css.helloSmallerText}><li>Choose one correct answer.</li></Typography>
        <Typography className={css.helloSmallerText}><li>Choose multiple correct answers.</li></Typography>
        <Typography className={css.helloSmallerText}><li>Fill in the gaps. To fill in the gap use ONE WORD, which makes sense both grammatically and logically.</li></Typography>
        </ul>
      </InfoBlock>
      <InfoBlock
        icon={<LightbulbOutlined className={css.icon} />}
        color="white"
      >
        <Typography component="h2" variant="h6" display="block" gutterBottom className={css.title}>
        Please do not cheat
        </Typography>
        <Typography className={css.helloSmallerText}>We cannot track if you are using third-party programmes to do the test or not. We rely on your honesty. The result is important for us as we will be able to help you organise a language improvement intervention if needed.</Typography>
      </InfoBlock>
    </Grid>
    <Link to="/test" className={css.link}>
      <Button className={css.button}>
        LET'S START
      </Button>
    </Link>
    <Typography className={css.footer}>Copyright Redhill Games Ltd 2022</Typography>
  </Container>;
};

export default Hello;