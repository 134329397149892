import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Button, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect } from "react";
import { redirectRequest } from "../authConfig";
import Logo from "./common/Logo";
import css from "./Register.module.css";

const Register = () => {
  const msal = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      msal.instance.acquireTokenRedirect(redirectRequest);
    }
  });

  return <Container className={css.container}>
    <Logo />
    <Typography component="h2" variant="h6" display="block" gutterBottom className={css.title}>
      ENGLISH LEVEL ASSESMENT TEST
    </Typography>
    <Button className={css.button}
      onClick={() => msal.instance.acquireTokenRedirect(redirectRequest)}
    >
      Login
    </Button>
    <Typography className={css.copyText}>Copyright Redhill Games Ltd 2022</Typography>
  </Container>;
};

export default Register;