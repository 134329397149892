import { useMsal } from "@azure/msal-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { CircularProgress, Paper, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import Title from "../common/Title";
import Blocks from "./Blocks";
import Questions from "./Questions";
import DefaultHeaders from "../helpers/DefaultHeaders";
import { Block } from "../../types";
import css from "./Questions.module.css";

const Admin = () => {
  const [fetching, setFetching] = useState(true);
  const [blocks, setBlocks] = useState<Block[]>([]);
  const msal = useMsal();

  useEffect(() => {
    const fetchData = async() => {
      try {
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/admin/blocks`, {
          headers: await DefaultHeaders(msal),
        });
        setBlocks(data);
      } catch(e) {
        console.error(e);
      }
      setFetching(false);
    };
    setFetching(true);
    fetchData();
  },[msal]);

  return <Container>
    <Paper className={css.displayFlexColumn}>
      <Title>Admin</Title>
      <Link to="question">
        <Typography>Create Question</Typography>
      </Link>
      <Link to="block">
        <Typography>Create Block</Typography>
      </Link>
      <Link to="users">
        <Typography>List users</Typography>
      </Link>
      {fetching ? <CircularProgress className={css.loader} /> :
      <React.Fragment>
        <Questions blocks={blocks} />
        <Blocks blocks={blocks} />
      </React.Fragment>}
    </Paper>
  </Container>;
};

export default Admin;