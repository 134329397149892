import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";

interface PulldownProps {
  name: string;
  arr: any[];
  handleStateInputChange: (event: SelectChangeEvent<any>) => void;
  variant?: any;
  label?: string;
  value: any;
}

const Pulldown = (props: PulldownProps) => {
  const { name, arr, variant, label , value, handleStateInputChange } = props;
  const selectedValue = value.value || value;
  return (
    <FormControl variant={variant}>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        name={name}
        onChange={handleStateInputChange}
        value={selectedValue}
      >
        {arr.map((x) => {
          const label = x.label || x;
          const value = x.value || x;
          return <MenuItem key={value} value={value}>
            {label}
          </MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

export default Pulldown;