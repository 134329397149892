import { Grid, TextareaAutosize, Typography } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Answer, QuestionProps } from "../../types";
import css from "./Question.module.css";

const FreeText = (props: QuestionProps) => {
  const questionData = props.question.data;
  const [answer, setAnswer] = useState<Answer>(props.answers[props.index]);

  useEffect(() => {
    props.updateAnswer(answer, props.index);
  },[answer]);

  const updateText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const {value} = e.target;
    const newAnswer = {...answer};
    newAnswer.answers[0] = value;
    setAnswer(newAnswer);
    props.updateAnswered(props.index);
  };

  return <React.Fragment>
    <Grid className={css.displayFlex}>
      <Typography className={css.questionNumber}>{props.index + 1}</Typography>
      <Typography className={css.questionTitle}>Answer by writing an essay</Typography>
    </Grid>
    <Typography className={css.questionBodyHeader}>{questionData.text}</Typography>
    <Grid className={css.questionBody}>
      <TextareaAutosize
        minRows={10}
        onChange={(e) => updateText(e)}
        value={answer.answers[0] || ""}
        cols={150}
      />
    </Grid>
  </React.Fragment>;
};

export default FreeText;