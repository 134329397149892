
import { Typography } from "@mui/material";
import React from "react";
import css from "./Title.module.css";

const Title = (props: React.PropsWithChildren) => {
  return (
    <Typography component="h2" variant="h6" display="block" gutterBottom className={css.title}>
      {props.children}
    </Typography>
  );
};

export default Title;