import React, { useEffect, useState } from "react";
import { QuestionData, QuestionType } from "../../types";
import FillTheGapsForm from "./FillTheGapsForm";
import FreeTextForm from "./FreeTextForm";
import MultipleChoiceForm from "./MultipleChoiceForm";
import SingleChoiceForm from "./SingleChoiceForm";

interface QuestionFormProps {
  type: QuestionType;
  question?: QuestionData;
  setQuestion: Function;
}

const QuestionForm = (props: QuestionFormProps) => {
  const [component, setComponent] = useState<React.ReactElement | null>(null);

  useEffect(() => {
    switch(props.type) {
      case "fillTheGaps":
      setComponent(<FillTheGapsForm question={props.question} setQuestion={props.setQuestion}/>);
        break;
      case "multipleChoice":
        setComponent(<MultipleChoiceForm question={props.question} setQuestion={props.setQuestion} />);
        break;
      case "singleChoice":
        setComponent(<SingleChoiceForm question={props.question} setQuestion={props.setQuestion} />);
        break;
      case "freeText":
        setComponent(<FreeTextForm question={props.question} setQuestion={props.setQuestion} />);
        break;
      default:
        break;
    }
  },[props.type, props.setQuestion]);

  return component;
};

export default QuestionForm;