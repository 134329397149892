import { Button, Grid, SelectChangeEvent, TextareaAutosize, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { QuestionData } from "../../types";
import Pulldown from "../common/Pulldown";
import Title from "../common/Title";

interface SingleChoiceFormProps {
  question?: QuestionData;
  setQuestion: Function;
}

const SingleChoiceForm = (props: SingleChoiceFormProps) => {
  const [data, setData] = useState<QuestionData>({text: [""], choices: [""], check: [0]});
  const [text, setText] = useState("");

  useEffect(() => {
    if (props.question !== undefined) {
      setData(props.question);
      setText(props.question.text[0]);
    }
  },[props.question]);

  const createQuestion = () => {
    props.setQuestion(data);
  };

  const addChoice = () => {
    const newData = {...data};
    newData.choices.push("");
    setData(newData);
  };

  const updateText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const {value} = e.target;
    setText(value);
    const newData = {...data};
    newData.text = [value];
    setData(newData);
  };

  const updateChoice = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, i: number) => {
    const {value} = e.target;
    const newData = {...data};
    newData.choices[i] = value;
    setData(newData);
  };

  const updateCheck = (e: SelectChangeEvent) => {
    const newData = {...data};
    const {value} = e.target;
    const choiceIndex = newData.choices.findIndex((choice) => choice === value);
    newData.check[0] = choiceIndex;
    setData(newData);
  };

  const removeChoice = (toRemove: number) => {
    const newData = {...data};
    newData.choices = newData.choices.filter((text, i) => i !== toRemove);
    setData(newData);
  };

  return <Grid>
  <Title>Create new single choice question</Title>
  <Button
    onClick={() => addChoice()}
  >
    Add choice at the end
  </Button>
  <Typography>Question text:</Typography>
  <TextareaAutosize
    minRows={3}
    onChange={(e) => updateText(e)}
    value={text}
  />
  {data.choices.map((choice, i) => {
    return <Grid key={`multipleChoice${i}`}>
      <Typography>Choice {i+1}:</Typography>
      <TextField value={data.choices[i]} onChange={(e) => updateChoice(e, i)}></TextField>
      <Button
        onClick={() => removeChoice(i)}
      >
        Remove this choice
      </Button>
    </Grid>;
  })}
  <Grid>
    <Typography>Correct answer</Typography>
    <Pulldown
      arr={[...data.choices]}
      name={"check0"}
      value={data.choices[data.check[0]]}
      label="Correct answer"
      handleStateInputChange={(e) => updateCheck(e)}
    />
  </Grid>
  <Button
    variant="contained"
    size="small"
    onClick={() => {
      createQuestion();
    }}
  >
    Submit
  </Button>
</Grid>;
};

export default SingleChoiceForm;