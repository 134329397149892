import { Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Answer, QuestionProps } from "../../types";
import css from "./Question.module.css";

const MultipleChoice = (props: QuestionProps) => {
  const questionData = props.question.data;
  const [answer, setAnswer] = useState<Answer>(props.answers[props.index]);

  useEffect(() => {
    props.updateAnswer(answer, props.index);
  },[answer]);

  const handleToggle = (index: number) => {
    const newAnswer = {...answer};
    const currentAnswer = newAnswer.answers.find((answer) => answer === index);
    newAnswer.answers = currentAnswer === undefined ?
      [index, ...newAnswer.answers] : newAnswer.answers.filter((answer) => answer !== index);
    setAnswer(newAnswer);
    props.updateAnswered(props.index, newAnswer.answers.length > 0);
  };

  return <React.Fragment>
    <Grid className={css.displayFlex}>
      <Typography className={css.questionNumber}>{props.index + 1}</Typography>
      <Typography className={css.questionTitle}>Select all appropriate answers</Typography>
    </Grid>
    <Typography className={css.multipleChoiceTitle}>{questionData.text}</Typography>
    <Grid className={css.multipleChoiceLowerPaddings}>
      <List>
      {questionData.choices.map((choice, i) => <ListItem
        disablePadding
        key={`multipleChoiceItem${i}`}
        role="listitem"
        button
        onClick={() => handleToggle(i)}
        >
        <ListItemIcon>
          <Checkbox checked={answer?.answers.includes(i)}/>
        </ListItemIcon>
        <ListItemText className={answer?.answers.includes(i) ? css.selectedText : css.fillTheGapsText}>{choice}</ListItemText>
      </ListItem>)}
      </List>
    </Grid>
  </React.Fragment>;
};

export default MultipleChoice;