import React from "react";
import css from "./Logo.module.css";

interface LogoProps {
  size?: string;
}

const Logo = (props: LogoProps) => {
  return (
    <img
      className={props.size === "small" ? css.logoSmall : css.logo}
      src={process.env.PUBLIC_URL + "/RHG_vertical_white_symbol_RGB.png"}
      alt="company logo"
    />
  );
};

export default Logo;