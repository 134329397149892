import { Grid, Paper } from "@mui/material";
import React, { ReactElement } from "react";
import css from "./InfoBlock.module.css";

interface InfoBlockProps extends React.PropsWithChildren {
  icon: ReactElement | null;
  color: string;
  css?: {
    readonly [key: string]: string;
};
}

const InfoBlock = (props: InfoBlockProps) => {
  const { color } = props;
  const usedCss = props.css === undefined ? css : props.css;
  return <Grid className={usedCss.infoBlock}>
    <Paper className={usedCss.infoPaper} style={{color: color}} >
      {props.icon}
      <Grid className={usedCss.infoBlockText}>
      {props.children}
      </Grid>
    </Paper>
  </Grid>;
};

export default InfoBlock;