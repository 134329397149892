import { useMsal } from "@azure/msal-react";
import { Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, Paper, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
// eslint-disable-next-line no-unused-vars, unused-imports/no-unused-imports, @typescript-eslint/no-unused-vars
import axios, { AxiosError } from "axios";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Block, BlockRule } from "../../types";
import Pulldown from "../common/Pulldown";
import Title from "../common/Title";
import DefaultHeaders from "../helpers/DefaultHeaders";
import css from "./Questions.module.css";

const EditBlock = () => {
  const [fetching, setFetching] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [blockName, setBlockName] = useState("");
  const [rule, setRule] = useState<BlockRule>({
    percentageCorrect: 70,
    passBlock: -1,
    failBlock: -1,
    passGrade: "",
    failGrade: "",
  });
  const [first, setFirst] = useState(false);
  const msal = useMsal();
  const blockId = parseInt(useParams().blockId || "", 10);

  useEffect(() => {
    const fetchData = async() => {
      try {
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/admin/blocks`, {
          headers: await DefaultHeaders(msal),
        });
        setBlocks(data);
      } catch(e) {
        console.error(e);
      }
      setFetching(false);
    };
    setFetching(true);
    fetchData();
  },[blockId]);

  useEffect(() => {
    const block = blocks.find((block) => block.id === blockId);
    setRule(block?.rule || {
      percentageCorrect: 70,
      passBlock: -1,
      failBlock: -1,
      passGrade: "",
      failGrade: "",
    });
    setBlockName(block?.name || "");
    setFirst(block?.first || false);
  },[blocks]);

  const updatePassBlock = (e: SelectChangeEvent) => {
    const {value} = e.target;
    setRule({...rule, passBlock: parseInt(value, 10)});
  };

  const updateFailBlock = (e: SelectChangeEvent) => {
    const {value} = e.target;
    setRule({...rule, failBlock: parseInt(value, 10)});
  };

  const updateFirst = (e: ChangeEvent<HTMLInputElement>) => {
    const {checked} = e.target;
    setFirst(checked);
  };

  const editBlock = async(name: string) => {
    setError("");
    setSuccess("");
    setSubmitting(true);
    try {
      const {data} = await axios.put(`${process.env.REACT_APP_API_URL}/admin/block`, {
        id: blockId,
        name,
        rule,
        first,
      }, {
        headers: await DefaultHeaders(msal),
      });
      setSuccess(data);
    } catch(e: AxiosError | any) {
      setError(e.message);
      console.error(e);
    }
    setSubmitting(false);
  };

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setBlockName(value);
  };

  const onPassGradeChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {value} = e.target;
    const newRule = {...rule};
    newRule.passGrade = value;
    setRule(newRule);
  };

  const onFailGradeChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {value} = e.target;
    const newRule = {...rule};
    newRule.failGrade = value;
    setRule(newRule);
  };

  const onPercentageCorrectChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {value} = e.target;
    const newRule = {...rule};
    newRule.percentageCorrect = parseInt(value, 10);
    setRule(newRule);
  };

  return <Container>
    <Paper>
      <Link to="/admin"><Typography>Back</Typography></Link>
      <Title>Edit Block {`${blockId}`}</Title>
      {fetching || submitting ? <CircularProgress className={css.loader}/> :
      <React.Fragment><Grid className={css.displayFlex}>
        <TextField
          label="Block name"
          value={blockName}
          onChange={onNameChange}
        />
        <TextField
          label="Percentage correct required"
          value={rule.percentageCorrect}
          onChange={onPercentageCorrectChange}
        />
        <Pulldown
          name="passBlock"
          arr={[{
            label: "Complete test",
            value: -1
          }, ...blocks.map((block) => {
            return {label: block.name, value: block.id};
          })]}
          handleStateInputChange={(e) => updatePassBlock(e)}
          value={{label: "Complete test", value: rule.passBlock}}
          label="Pass block"
        />
        <Pulldown
          name="failBlock"
          arr={[{
            label: "Complete test",
            value: -1
          }, ...blocks.map((block) => {
            return {label: block.name, value: block.id};
          })]}
          handleStateInputChange={(e) => updateFailBlock(e)}
          value={{label: "Complete test", value: rule.failBlock}}
          label="Fail block"
        />
        <TextField
          label="Grade when passing"
          value={rule.passGrade || ""}
          onChange={(e) => onPassGradeChange(e)}
        />
        <TextField
          label="Grade when failing"
          value={rule.failGrade || ""}
          onChange={(e) => onFailGradeChange(e)}
        />
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={first} onChange={(e) => updateFirst(e)}/>} label="This is the first block" />
        </FormGroup>
      </Grid>
      <Button
        variant="contained"
        size="small"
        onClick={() => {
          editBlock(blockName);
        }}
      >
        Edit
      </Button>
      </React.Fragment>}
      <Typography>{success}</Typography>
      <Typography className={css.error}>{error}</Typography>
    </Paper>
  </Container>;
};

export default EditBlock;