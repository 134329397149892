import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars, unused-imports/no-unused-imports, @typescript-eslint/no-unused-vars
import axios, { AxiosError } from "axios";
import { Block, QuestionData, QuestionResponse, QuestionType, questionTypes } from "../../types";
import DefaultHeaders from "../helpers/DefaultHeaders";
import { CircularProgress, Container, Grid, Paper, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import Title from "../common/Title";
import css from "./Questions.module.css";
import Pulldown from "../common/Pulldown";
import { getQuestionDescription, getQuestionTypeIdFromDescription } from "../helpers/GetQuestionType";
import QuestionForm from "./QuestionForm";

const EditQuestion = () => {
  const [fetching, setFetching] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [type, setType] = useState<QuestionType>("fillTheGaps");
  const [block, setBlock] = useState<Block>({name:""});
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [question, setQuestion] = useState<QuestionResponse>();
  const msal = useMsal();
  const questionId = parseInt(useParams().questionId || "", 10);

  const updateQuestion = (questionData: QuestionData) => {
    if (question !== undefined) {
      setError("");
      setSuccess("");
      setSubmitting(true);
      const fetchData = async() => {
        try {
          const {data} = await axios.put(`${process.env.REACT_APP_API_URL}/admin/question`, {
            id: questionId,
            type,
            block: block.id,
            data: questionData,
          }, {
            headers: await DefaultHeaders(msal),
          });
          setSuccess(data);
        } catch(e: AxiosError | any) {
          setError(e.message);
          console.error(e);
        }
        setSubmitting(false);
      };
      fetchData();
    }
  };

  useEffect(() => {
    const fetchData = async() => {
      try {
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/admin/blocks`, {
          headers: await DefaultHeaders(msal),
        });
        setBlocks(data);
      } catch(e) {
        console.error(e);
      }
      try {
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/admin/question/${questionId}`, {
          headers: await DefaultHeaders(msal),
        });
        setQuestion(data);
      } catch(e) {
        console.error(e);
      }
      setFetching(false);
    };
    fetchData();
  },[fetching]);

  useEffect(() => {
    setType(question?.type || "fillTheGaps");
  },[question]);

  useEffect(() => {
    setBlock(blocks.find((block) => block.id === question?.block) || {name:""});
  },[blocks]);

  return <Container>
    <Paper className={css.displayFlexColumn}>
      <Link to="/admin"><Typography>Back</Typography></Link>
      <Title>Edit Question {questionId}</Title>
      {fetching || submitting ? <CircularProgress className={css.loader}/> :
      <React.Fragment><Grid>
        <Pulldown
          arr={questionTypes.map((question) => question.description)}
          name="questionType"
          value={getQuestionDescription(type)}
          label="Question type"
          handleStateInputChange={(e) => {
            const { value } = e.target;
            setQuestion(undefined);
            setType(getQuestionTypeIdFromDescription(value));
          }}
        />
        <Pulldown
          arr={blocks.map((block) => block.name)}
          name="blocks"
          value={block.name}
          label="Block"
          handleStateInputChange={(e) => {
            const { value } = e.target;
            setBlock(blocks.find((block) => block.name === value) || {name: ""});
          }}
        />
      </Grid>
    <QuestionForm key={type} type={type} question={question?.data as QuestionData} setQuestion={updateQuestion} />
    <Typography>{success}</Typography>
    <Typography className={css.error}>{error}</Typography>
    </React.Fragment>}

    </Paper>
  </Container>;
};

export default EditQuestion;