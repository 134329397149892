import { useMsal } from "@azure/msal-react";
import { CircularProgress, Container, Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import DefaultHeaders from "./helpers/DefaultHeaders";
import css from "./Completed.module.css";
import InfoBlock from "./common/InfoBlock";
import { CheckCircleOutline } from "@mui/icons-material";
import { Link } from "react-router-dom";
import infoCss from "./CompletedInfoBlock.module.css";

const Completed = () => {
  const [fetching, setFetcing] = useState(true);
  const [name, setName] = useState<string|undefined>("");
  const [result, setResult] = useState("");
  const msal = useMsal();

  useEffect(() => {
    const accountName = msal.accounts.find((account) => account.name)?.name;
    if (accountName) {
      const firstNameUpperCase = accountName.split(" ").find((str) => str)?.toLocaleUpperCase();
      setName(firstNameUpperCase);
    }
  },[msal.accounts]);

  useEffect(() => {
    const fetchData = async() => {
      try {
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/result`, {
          headers: await DefaultHeaders(msal),
        });
        setResult(data.result);
      } catch(e) {
        console.error(e);
      }
      setFetcing(false);
    };
    if (fetching) {
      fetchData();
    }
  },[fetching]);

  return <Container className={css.completedContainer}>
    {fetching ? <CircularProgress className={css.loader}/> :
    <React.Fragment>
      <img
        src="RHG_vertical_white_symbol_RGB.png"
        alt="Redhill Games"
      />
      <Typography className={css.congratulationsText}>Congratulations, {name}!</Typography>
      <Typography className={css.passedText}>You have successfully passed the test. Your result is:</Typography>
      <Grid className={css.infoContainer}>
        <InfoBlock
          icon={<CheckCircleOutline className={css.icon}/>}
          color="#F5F5F5"
          css={infoCss}
        >
          <Typography className={result.length > 6 ? css.resultTextLong : css.resultText}>{result}</Typography>
        </InfoBlock>
      </Grid>
      <Typography className={css.completedQuestions}>For any questions please&nbsp;
        <Link
          to="#"
          onClick={() => window.location.href = "mailto:katya@redhillgames.com"}
          className={css.link}
        >
          email HR
        </Link>
      </Typography>
    </React.Fragment>}
    <Typography className={css.footer}>Copyright Redhill Games Ltd 2022</Typography>
  </Container>;
};

export default Completed;