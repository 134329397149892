export const msalConfig = {
  auth: {
    clientId: "fded9959-e165-424f-9b8e-a7e156a92620", // This is the ONLY mandatory field that you need to supply.
    //authority: "https://login.microsoftonline.com/common", // Defaults to "https://login.microsoftonline.com/common"
    //redirectUri: "http://localhost:3000", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.href
    //postLogoutRedirectUri: "http://localhost:3000/signout", // Simply remove this line if you would like navigate to index page after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
};

export const loginRequest = {
  scopes: ["openid", "profile", "email"],
};

export const redirectRequest = {
  scopes: ["openid", "profile", "email"],
};