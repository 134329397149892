import { Button, Checkbox, Divider, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "./common/Title";
import LogoWithText from "./common/LogoWithText";
import css from "./Terms.module.css";
import confirmCss from "./Confirm.module.css";
import { Container } from "@mui/system";
import { useConfirm } from "material-ui-confirm";

const Terms = () => {
  const [accepted, setAccepted] = useState(false);
  const navigate = useNavigate();
  const confirm = useConfirm();

  useEffect(() => {
    const storedValue = localStorage.getItem("accepted");
    if (storedValue === "true") {
      setAccepted(true);
    }
  }, []);

  const accept = () => {
    const newValue = !accepted;
    localStorage.setItem("accepted", newValue.toString());
    setAccepted(newValue);
  };

  const checkAcceptedAndNavigate = async() => {
    if (accepted) {
      navigate("/hello");
    } else {
      await confirm({
        title: "Warning",
        description: "Sorry but you can't begin the test without accepting our \"Terms and Conditions\".",
        cancellationButtonProps: {
          disabled: true,
          className: confirmCss.hideCancelButton
        },
        titleProps: {className: confirmCss.confirmTitle},
        contentProps: {className: confirmCss.confirmBody},
        confirmationButtonProps: {style: {margin: "0px 70px 70px 70px"}},
        confirmationText: "Back"
      });
    }
  };

  return <Container>
    <Paper className={css.container}>
      <Grid className={css.header}>
        <LogoWithText />
        <Divider />
      </Grid>
      <Grid className={css.termsContainer}>
        <Title>Terms and conditions</Title>
        <Typography gutterBottom className={css.paragraph}>
          We are committed to protecting and respecting your privacy. This Privacy Policy (“Policy”) describes how we treat information that you provide to us or that we collect about you.

By “Personal Data”, we refer to data that relates to you as an identified or identifiable natural person. The term “Personal Data” may include your name, your address, your telephone number, your e-mail address, your age, your gender, your game preferences, for instance. Anonymous information, which we are not in a position to relate to you, does not qualify as “Personal Data”.
        </Typography>
        <Title>1. CONTROLLER'S NAME AND CONTACT DETAILS </Title>
        <Typography gutterBottom>
          Controller in the sense of the General Data Protection Regulation (GDPR) and other data protection or data privacy laws in the Member States of the European Union and other guidelines with a data protection nature regarding our websites owned or operated by Redhill Games:
        </Typography>
        <Typography>
        Redhill Games Oy
        </Typography>
        <Typography>
        Lönnrotinkatu 5
        </Typography>
        <Typography>
        00120 Helsinki
        </Typography>
        <Typography>
        FINLAND
        </Typography>
        <Typography gutterBottom className={css.paragraph}>
        Email: info@redhillgames.com
        </Typography>
        <Title>2. CONTACT DETAILS OF THE DATA PROTECTION OFFICER (DPO) </Title>
        <Typography gutterBottom>
        The contact details of Data Protection Officer of Redhill Games are:
        </Typography>
        <Typography>
        Redhill Games Data Protection Officer
        </Typography>
        <Typography>
        Zinonos Sozou, 12
        </Typography>
        <Typography>
        Nicosia 1075
        </Typography>
        <Typography>
        Cyprus
        </Typography>
        <Typography className={css.paragraph}>
        Email: info@redhillgames.com
        </Typography>
      </Grid>
      <Divider />
      <Grid className={css.termsFooter}>
        <Grid className={css.termsAccepted}>
          <label htmlFor="accepted" className={css.termsFooterText}>I accept the terms and conditions</label>
          <Checkbox
            id="accepted"
            checked={accepted}
            onClick={() => accept()}
          />
        </Grid>
        <Button className={css.button}
          onClick={() => checkAcceptedAndNavigate()}
        >
          Accept
        </Button>
      </Grid>
    </Paper>
    <Typography className={css.copyText}>Copyright Redhill Games Ltd 2022</Typography>
  </Container>;
};

export default Terms;