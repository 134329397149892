import { Button, Grid, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { QuestionData } from "../../types";
import Title from "../common/Title";

interface FillTheGapsFormProps {
  question?: QuestionData;
  setQuestion: Function;
}

const FillTheGapsForm = (props: FillTheGapsFormProps) => {
  const [data, setData] = useState<QuestionData>({text: [""], choices: [], check: [[""]]});

  useEffect(() => {
    if (props.question !== undefined) {
      setData(props.question);
    }
  },[props.question]);

  const updateQuestion = () => {
    props.setQuestion(data);
  };

  const addText = () => {
    const newData = {...data};
    newData.text.push("");
    setData(newData);
  };

  const addCheck = (gapNumber: number) => {
    const newData = {...data};
    if (newData.check[gapNumber] === undefined) {
      newData.check[gapNumber] = [];
    }
    newData.check[gapNumber].push("");
    setData(newData);
  };

  const removeText = (toRemove: number) => {
    const newData = {...data};
    newData.text = newData.text.filter((text, i) => i !== toRemove);
    setData(newData);
  };

  const removeCheck = (gapNumber: number, toRemove: number) => {
    const newData = {...data};
    newData.check[gapNumber] = newData.check[gapNumber].filter((text: any, i: number) => i !== toRemove);
    if (newData.check[gapNumber].length === 0) {
      newData.check.splice(gapNumber, 1);
    }
    setData(newData);
  };

  const updateText = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, i: number) => {
    const newData = {...data};
    const {value} = e.target;
    newData.text[i] = value || "";
    setData(newData);
  };

  const updateCheck = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, gapNumber: number, i: number) => {
    const newData = {...data};
    const {value} = e.target;
    newData.check[gapNumber][i] = value || "";
    setData(newData);
  };

  return <Grid>
    <Title>Create/Edit fill the gaps question</Title>
    <Button
      onClick={() => addText()}
    >
      Add part at the end
    </Button>
    <Button
      onClick={() => addCheck(data.check.length)}
    >
      Add check at the end
    </Button>
    {data.text.map((text, i) => {
      return <Grid key={`fillthegaps${i}`}>
        <Typography>Part {i+1}</Typography>
        <TextField onChange={(e) => updateText(e, i)} value={text}></TextField>
        <Button
          onClick={() => removeText(i)}
        >
          Remove this part
        </Button>
      </Grid>;
    })}
    {data.check.map((checks, i) => {
      if (checks.length === 0) {
        return <Grid key={`fillthegaps${i}${0}`}>
        <Typography>Gap {i+1} Check {1}</Typography>
        <TextField onChange={(e) => updateCheck(e, i, 0)} value={""}></TextField>
        <Button
          onClick={() => removeCheck(i, 0)}
        >
          Remove this check
        </Button>
        <Button
          onClick={() => addCheck(i)}
        >
          Add check to Gap {i+1}
        </Button>
      </Grid>;
      }
      return checks.map((check: any[], j: number) =>
        <Grid key={`fillthegaps${i}${j}`}>
          <Typography>Gap {i+1} Check {j+1}</Typography>
          <TextField onChange={(e) => updateCheck(e, i, j)} value={check}></TextField>
          <Button
            onClick={() => removeCheck(i, j)}
          >
            Remove this check
          </Button>
          <Button
            onClick={() => addCheck(i)}
          >
            Add check to Gap {i+1}
          </Button>
        </Grid>
      );
    })}
    <Button
      variant="contained"
      size="small"
      onClick={() => {
        updateQuestion();
      }}
    >
      Submit
    </Button>
  </Grid>;
};

export default FillTheGapsForm;