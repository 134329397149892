import { PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { createTheme, ThemeProvider } from "@mui/material";
import { Container } from "@mui/system";
import { ConfirmProvider } from "material-ui-confirm";
import { HashRouter as Router } from "react-router-dom";
import css from "./App.module.css";
import AuthenticatedApp from "./AuthenticatedApp";
import Register from "./components/Register";

interface AppProps {
  pca: PublicClientApplication;
}

const App = (props: AppProps) => {
  const { pca } = props;

  const theme = createTheme({
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: "28px",
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontSize: "28px",
            color: "#66C8FF",
            border: "2px solid #66C8FF",
            borderRadius: 0,
            backgroundColor: "rgba(102, 200, 255, 0.1)"
          },
          input: {
            height: "17px"
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            maxWidth: "830px",
          }
        }
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: "30px",
          }
        }
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            fontSize: "28px",
          }
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            justifyContent: "center",
          }
        }
      },
      MuiButton: {
        variants: [
          {
            props: { color: "primary"},
            style: {
              backgroundColor: "#E9F3FF",
              color: "#2F2E2C",
              fontWeight: 600,
              fontSize: 24,
              width: "314px"
            }
          }
        ]
      },
      MuiRadio: {
        variants: [
          {
            props: { color: "primary", checked: true },
            style: {
              color: "#66C8FF !important",
              borderBottomColor: "#66C8FF !important",
            }
          },
        ]
      },
      MuiCheckbox: {
        variants: [
          {
            props: { color: "primary", checked: true },
            style: {
              color: "#66C8FF !important",
            }
          }
        ]
      }
    }
  });

  return (
    <Container style={css} className={css.appContainer}>
      <MsalProvider instance={pca}>
        <AuthenticatedTemplate>
          <Router>
            <ThemeProvider theme={theme}>
              <ConfirmProvider>
                <AuthenticatedApp />
              </ConfirmProvider>
            </ThemeProvider>
          </Router>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Register />
        </UnauthenticatedTemplate>
      </MsalProvider>
    </Container>
  );
};

export default App;
